// IMPORT
// Mods Do not remove linebreaks in this file until DOMContentLoaded
import {NullSwiper} from "./mods/nullswiper.js"; 

// Helpers
import {ViewportFix} from "./helpers/viewportFix.js";
import {ClientInformations} from "./helpers/clientInformations.js";
import {EventHandler} from "./helpers/eventHandler.js";
import {Simplify} from "./helpers/simplify.js";
import {AddNullInteract} from "./helpers/nullInteract.js";


// Static
import {menuHandler} from "./static/menuHandler.js";
import {SubMenu} from "./static/submenu.js";
import {Cookie} from "./static/cookie.js";
import {Cursor} from "./static/cursor.js";

// Dynamic
import {MapHandler} from "./dynamic/map.js";













const helperFuncs = {
    'viewportFix': new ViewportFix(),
    'clientInformations': new ClientInformations(),
    'simplify': new Simplify(),
    'nullInteract': new AddNullInteract
};

// INITIALIZE
const dynamicFuncs = {
    "NullSwiper": new NullSwiper(),   // Mods dont remove this line
    'eventHandler': new EventHandler(),
    //'navigation': new Navigation(),
    'MapHandler': new MapHandler()
};

const staticFuncs = {
    'menuHandler': new menuHandler(),
    'subMenu': new SubMenu(),
    'cookie': new Cookie(),
    'cursor': new Cursor()
};


// RUN Helperfunctions
for (let functions in helperFuncs) {
    if (typeof (helperFuncs[functions]) === "object") {
        helperFuncs[functions].init();
    }
}

gsap.registerPlugin(ScrollToPlugin);

// Run Static functions and dynamic functions on dom content loaded
document.addEventListener("DOMContentLoaded", () => {

    let path = window.location.pathname;
    if(path === '/de/karte/') {
        // document.querySelector('.marquee__big').classList.add('hidden');
    }

    if(path === '/') {
        let menuHeight = document.querySelector('[data-language]').clientHeight;
        let mapHeight = Client.windowHeight - menuHeight;
        document.querySelector('#map__small').style.height = mapHeight + 'px';
    }

    window.addEventListener('resize', () => {
       if(window.location.pathname === '/') {
           let menuHeight = document.querySelector('[data-language]').clientHeight;
           let mapHeight = Client.windowHeight - menuHeight;
           document.querySelector('#map__small').style.height = mapHeight + 'px';
       }
    });

    for (let functions in staticFuncs) {
        if (typeof (staticFuncs[functions]) === "object") {
            staticFuncs[functions].init();
        }
    }

    for (let functions in dynamicFuncs) {
        if (typeof (dynamicFuncs[functions]) === "object") {
            dynamicFuncs[functions].init();
        }
    }
})

// BARBA RELATED
function delay(n) {                                                                                 // DELAY HELPER FOR ASYNC FUNCTIONS WITH AWAIT
    n = n || 2000;
    return new Promise(done => {
        setTimeout(() => {
            done();
        }, n);
    });
}
let header = document.querySelector('header');
let contentWrapper = document.querySelector('.transition');
let map = document.querySelector('#map__small');
let localize = document.querySelector('.map__localize');

// Enter Animation
let eaParent = document.querySelector('.loading__screen');
let eaWrapper = eaParent.querySelector('.wrapper');
let eaSpacer = eaWrapper.querySelectorAll('.spacer');
let spacerSVGs = [eaSpacer[0].querySelector('svg'), eaSpacer[1].querySelector('svg')];


barba.init({
    sync: true,
    schema: {
        prefix: 'data-routing'
    },

    transitions: [
        {
            async once(data) {
                let spacerHeights = [spacerSVGs[0].scrollHeight, spacerSVGs[1].scrollHeight];
                let x = 0.8;

                let tl = gsap.timeline({repeat: 0});
                tl.set([spacerSVGs], {maxHeight: 0});
                tl.to([eaWrapper], {autoAlpha: 1, duration: 1.25 * x, ease: 'sine.inOut'}, "+=0.0");
                tl.to([eaWrapper], {translateY: 0, duration: 1.25 * x, ease: 'expo.inOut'}, "-=" + 1.25 * x);
                tl.to([spacerSVGs[0]], {maxHeight: spacerHeights[0], duration: 1.25 * x, ease: 'expo.inOut'}, "-=" + 1.25 * x);
                tl.to([spacerSVGs[1]], {maxHeight: spacerHeights[1], duration: 1.25 * x, ease: 'expo.inOut'}, "-=" + 1.25 * x);
                tl.to([eaParent], {autoAlpha: 0, duration: 1.25 * x, ease: 'sine.inOut'}, "+=1.05");

            },

            async leave(data) {
                // Set runtime to async
                const done = this.async();
                header.style.pointerEvents = 'none';

                // Page transition fadeout
                gsap.fromTo([contentWrapper, map, localize], {opacity: 1}, {opacity: 0, duration: 0.35, ease: 'Power3.out'});


                let path = data.next.url.path;

                // Marquee positions
                if(path === '/') {
                    // document.querySelector('.marquee__big').classList.remove('hidden');
                } else {
                    // document.querySelector('.marquee__big').classList.add('hidden');
                }

                // Time to wait for barba page change for your animation to finish
                await delay(400);
                done();
            },

            async enter(data) {
                // Change Body class
                ajax('getBodyClass')
                    .then((answer) => {
                        // Remove all classes first and add new ones
                        document.body.removeAttribute('class');
                        document.body.setAttribute('class', answer);
                    });

                // Remove Compass Class
                document.querySelector('.content__wrapper').classList.remove('compass', 'small__compass');

                // Map positions
                await delay(350);
                let menuHeight = document.querySelector('[data-language]').clientHeight;
                let mapHeight = Client.windowHeight - menuHeight;

                let path = data.next.url.path;
                switch (path) {
                    case '/':
                        document.body.classList.add('program');
                        document.body.classList.remove('map');
                        document.querySelector('#map__small').style.height = mapHeight + 'px';
                        break;
                    case '/de/karte/':
                        document.body.classList.remove('program');
                        document.body.classList.add('map');
                        document.querySelector('#map__small').style.height = '100vh';
                        break;

                    default:
                        document.querySelector('#map__small').style.height = '0px';
                        document.body.classList.remove('program', 'map');
                        break;
                }

                // Page Transition fadein
                gsap.fromTo([contentWrapper, map, localize], {opacity: 0}, {opacity: 1, duration: 0.35, ease: 'Power3.in'});
            }
        }
    ]
});


barba.hooks.enter(() => {                                                                         // RESET SCROLLPOSITION ON PAGE CHANGE
    window.scrollTo(0, 0);
});


barba.hooks.after(() => {
    // Call your page enter transition here
    header.removeAttribute('style');


    // Update the dynamic functiosn
    for (let functions in dynamicFuncs) {
        if (typeof (dynamicFuncs[functions]) === "object") {
            dynamicFuncs[functions].update();
        }
    }
});
